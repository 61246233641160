import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Layout, Icon } from "antd";
import SideNavigation from "../SideNavigationContainer/index";
import { Grid } from "@mui/material";
import { Auth } from "aws-amplify";

// import { Card, CardContent, Grid, Typography } from "@mui/material";

const ContainerLayout: React.FC<{
  data: string | null;
}> = ({ children, data }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout className="cover" id="app-header">
      <Layout.Sider
        className="cover"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        <SideNavigation />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: "#ffffff", padding: 10 }}>
          <Icon
            style={{ verticalAlign: "super" }}
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={toggleCollapsed}
          />
          <div
            style={{ verticalAlign: "super", float: "right", paddingRight: 10 }}
          >
            <h1> Hi {data}</h1>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#ffffff",
            minHeight: 280,
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const CalendarContainer: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);
  const eventDialogRef = useRef<HTMLDivElement | null>(null);
  const [data, setData] = useState<string | null>(null);

  useEffect(() => {
    fetchEvents();
    get_user_data();
  }, []);

  async function get_user_data() {
    try {
      let cart = await Auth.currentSession();
      setData(cart.getIdToken().payload["name"]);
    } catch (error) {
      console.error("Error getting user data:", error);
    }
  }

  const fetchEvents = async () => {
    try {
      const response = await fetch(
        "https://blekg0rmw1.execute-api.eu-west-2.amazonaws.com/dev/list/events/data?year=2024"
      );
      const data = await response.json();
      if (response.ok) {
        setEvents(data.data);
      } else {
        console.error("Failed to fetch events:", data.message);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleEventMouseEnter = (info: any) => {
    const dialog = document.createElement("div");
    dialog.innerHTML = `
      <div>
        <strong>${info.event.title}</strong>
      </div>
      <div>
        Date: ${info.event.start.toLocaleDateString()}
      </div>
    `;
    dialog.style.position = "absolute";
    dialog.style.backgroundColor = "white";
    dialog.style.padding = "10px";
    dialog.style.border = "1px solid black";
    dialog.style.zIndex = "1000"; // Ensure it appears above other elements
    dialog.style.left = `${info.jsEvent.pageX}px`;
    dialog.style.top = `${info.jsEvent.pageY}px`;

    document.body.appendChild(dialog);
    eventDialogRef.current = dialog;
  };

  const handleEventMouseLeave = () => {
    if (eventDialogRef.current) {
      document.body.removeChild(eventDialogRef.current);
      eventDialogRef.current = null;
    }
  };

  const handleEventChange = (info: any) => {
    console.log("info: ", info);
  };
  const handleEventContent = (arg: any) => {
    const { event } = arg;
    const titleHtml = `<strong>${event.title}</strong>`;
    return { html: titleHtml, style: "margin-top: -20px" };
  };

  return (
    <ContainerLayout data={data}>
      <div style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              events={events.map((event) => ({
                title: event.event_name,
                start: event.start_timestamp,
                end: event.end_timestamp,
              }))}
              eventChange={handleEventChange}
              eventMouseEnter={handleEventMouseEnter}
              eventMouseLeave={handleEventMouseLeave}
              height="auto"
              buttonText={{
                today: "Today",
              }}
              eventContent={handleEventContent}
            />
          </Grid>
          {/* <Grid item xs={4} style={{ marginTop: "54px" }}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Events
                </Typography>
                {events.map((event, index) => (
                  <div key={index} style={{ padding: "10px" }}>
                    <Typography variant="body2" component="p">
                      {event.event_name}
                    </Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </div>
    </ContainerLayout>
  );
};

export default CalendarContainer;
