import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Form, Icon, Input, Spin, message, notification } from "antd";
import { Redirect } from "react-router-dom";
import image from "../../Assets/IRIS-Logo.png";
import { Grid } from "@mui/material";
interface ForgotPasswordProps {
  // Your component props here
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [confirmationCodeSent, setConfirmationCodeSent] =
    useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);

  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setConfirmationCodeSent(true);
    } catch (error: any) {
      console.log("Error:", error);
      message.error(error.message); // Display error as a snackbar
    }
  };

  const handleResetPassword = async () => {
    try {
      if (!confirmationCode) {
        return;
      }
      await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
      notification.error({
        message: "Password Reset Successful!",
        description: "Your password has been updated successfully",
        placement: "topRight",
        duration: 1.5,
      });
      setResetSuccess(true);
    } catch (error: any) {
      console.log("Error:", error);
      message.error(error.message); // Display error as a snackbar
    }
  };

  if (resetSuccess) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <img
        src={image}
        style={{ height: "150px", width: "350px", display: "flex" }}
        alt="IRIS Logo"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Grid
          container
          md={6}
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <div>
            <h1 style={{ fontSize: "48px" }}>Welcome</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  content: '""',
                  borderLeft: "2px solid red",
                  height: "45px",
                  marginRight: "20px",
                }}
              ></div>
              <p style={{ fontSize: "16px", width: "300px" }}>
                Log in and view your data with the Iris platform
              </p>
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
            marginLeft: "10%",
          }}
        >
          {!confirmationCodeSent && !resetSuccess && (
            <Form
              style={{ width: "300px" }}
              onSubmit={(e) => {
                e.preventDefault();
                handleForgotPassword();
              }}
            >
              <Form.Item>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  style={{ borderRadius: "10px", height: "45px" }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  {confirmationCodeSent ? (
                    <Spin indicator={<Icon type="loading" spin />} />
                  ) : (
                    "Send Confirmation Code"
                  )}
                </Button>
              </Form.Item>
            </Form>
          )}
          {confirmationCodeSent && !resetSuccess && (
            <>
              <Form
                style={{ marginTop: "8%", width: "300px" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleResetPassword();
                }}
              >
                <Form.Item>
                  <p>Password reset for: {email}</p>
                  <Input
                    type="text"
                    placeholder="Enter confirmation code"
                    style={{ borderRadius: "10px", height: "45px" }}
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    prefix={
                      <Icon
                        type="number"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    style={{ borderRadius: "10px", height: "45px" }}
                    onChange={(e) => setNewPassword(e.target.value)}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    {resetSuccess
                      ? "Password successfully reset"
                      : "Reset Password"}
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ForgotPassword;
