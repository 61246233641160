import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

/** Container components */
import LoginContainer from "../Containers/LoginContainer";
import SignupContainer from "../Containers/SIgnupContainer";
import ConfirmEmailContainer from "../Containers/VerifyEmailContainer";
import DashBoardContainer from "../Containers/DashboardContainer";
import ProductContainer from "../Containers/ProductContainer";
import ForgotPassword from "../Containers/ForgotPassword";
import CalendarContainer from "../Containers/CalendarContainer";
import FeedContainer from "../Containers/FeedContainer";
import SMSDataContainer from "../Containers/SMSDataContainer";
import DailyDataContainer from "../Containers/DailyDataContainer";
import PurchaseDataContainer from "../Containers/PurchaseDataContainer";

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <React.Fragment>
          <Route exact={true} path="/" component={LoginContainer} />
          <Route path="/login" component={LoginContainer} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/signup" component={SignupContainer} />
          <Route path="/verify-code" component={ConfirmEmailContainer} />
          <Route path="/dashboard" component={DashBoardContainer} />
          <Route path="/product" component={ProductContainer} />
          <Route path="/calendar" component={CalendarContainer} />
          <Route path="/feed" component={FeedContainer} />
          <Route path="/daily-sms" component={SMSDataContainer} />
          <Route path="/daily-data" component={DailyDataContainer} />
          <Route path="/purchase-data" component={PurchaseDataContainer} />
        </React.Fragment>
      </Router>
    );
  }
}

export default AppRouter;
