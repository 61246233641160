import React, { useState, useEffect } from "react";
import SideNavigation from "../SideNavigationContainer";
import moment from "moment";
import { Layout, Icon } from "antd";
import { Auth } from "aws-amplify";
import { ThreeDots } from "react-loader-spinner";
import { StackedBarplot } from '../../Components/StackChart';

const defaultFromDate = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD");
const defaultToDate = moment(new Date()).format("YYYY-MM-DD");

const defaultGroups = {
  'Combo...': [
    'Combo8P',           'Combo30DPlus',
    'Combo7D',           'ComodinVoz20Min',
    'Combo15D',          'Combo7P',
    'Combo10GBu',        'Combo4P',
    'ComodinVozIlim_1D', 'Combo30D',
    'Combo6',            'Combo2050',
    'Combo1025',         'Combo2550'
  ],
  'No...': [ 'Nocturno' ],
  'MB...': [ '150MB', '30MB', '300MB' ],
  'Comod...': [ 'COMODINUNIVERSITARIO' ],
  'Voz...': [ '5Voz', 'Buzon Voz' ],
  'SMS...': [ '30SMS' ]
}

const filterKeysV2 = (obj: any, key: any) => {
  const newObj: any = {};
  newObj['x'] = obj['x']
  if (key in obj) {
    newObj[key] = obj[key]
  }
  return newObj;
}

// function generateRandomColors(numColors: number) {
//   const colors = [];
//   const colorSet = new Set();

//   // Helper function to generate a random color code
//   function getRandomColor() {
//       const letters = '0123456789ABCDEF';
//       let color = '#';
//       for (let i = 0; i < 6; i++) {
//           color += letters[Math.floor(Math.random() * 16)];
//       }
//       return color;
//   }

//   while (colors.length < numColors) {
//       const newColor = getRandomColor();
//       if (!colorSet.has(newColor)) {
//           colorSet.add(newColor);
//           colors.push(newColor);
//       }
//   }

//   return colors;
// }

const ContainerLayout: React.FC<{
  data: string | null;
}> = ({ children, data }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="cover" id="app-header">
      <Layout.Sider
        className="cover"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        <SideNavigation />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: "#ffffff", padding: 10 }}>
          <Icon
            style={{ verticalAlign: "super" }}
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={toggleCollapsed}
          />
          <div
            style={{ verticalAlign: "super", float: "right", paddingRight: 10 }}
          >
            <h1> Hi {data}</h1>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#ffffff",
            minHeight: 280,
            flex: "none",
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const PurchaseDataContainer: React.FC = () => {
  const [userData, setUserData] = useState<string | null>(null);
  const [allChartData, setAllChartData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [selectedDates, setSelectedDates] = useState<any[]>([defaultFromDate, defaultToDate]);
  const [colors, setColors] = useState<any[]>([]);
  const [isChecked, setIsChecked] = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const [singleData, setSingleData] = useState(false);
  const [colorMap, setColorMap] = useState({});
  const [apiData, setAPIData] = useState({})
  const [selectedValue, setSelectedValue] = useState('totalData');
  const [groups, setGroups] = useState(defaultGroups);

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'totalData') {
      setDynamicColumnsData((apiData as any).totalDataChart);
      setChartData((apiData as any).totalDataChart);
      setAllChartData((apiData as any).totalDataChart);
    } else {
      setDynamicColumnsData((apiData as any).countDataChart);
      setChartData((apiData as any).countDataChart);
      setAllChartData((apiData as any).countDataChart);
    }
  };

  useEffect(() => {
    fetchData(selectedDates);
    get_user_data();
  }, []);

  const setDynamicColumnsData = (data: any) => {
    let dynamicColumns: any[] = [];
    for (let elem of data) {
      for (const key of Object.keys(elem)) {
        if (key !== 'x') {
          dynamicColumns.push(key)
        }
      }
    }
    dynamicColumns = dynamicColumns.filter((value, index, array) => array.indexOf(value) === index);
    setColumns(dynamicColumns as any);
    const colorsMap: any = [];
    for (const elem of dynamicColumns) {
      colorsMap.push((colorMap as any)[elem]);
    }
    setColors(colorsMap)
  }

  const fetchData = async (dates: string[] = []) => {
    try {
      let url = "https://blekg0rmw1.execute-api.eu-west-2.amazonaws.com/dev/purchase-data";
      if (dates.length) {
        url += `?startdate=${dates[0]}&&enddate=${dates[1]}`
      }
      const response = await fetch(
        url
      );
      const responseData = await response.json();
      setAPIData(responseData.data);
      setDynamicColumnsData(responseData.data.totalDataChart);
      setChartData(responseData.data.totalDataChart);
      setAllChartData(responseData.data.totalDataChart);
      setColors(responseData.data.colors);
      setColorMap(responseData.data.colorMap);
      setLoading(false);
      const groupedProducts: any = {};
      for (const key in responseData.data.colorMap) {
        const name = key.split(/[0-9_]+/)[0]; // Extracting the name part
        if (!groupedProducts[name]) {
            groupedProducts[name] = [];
        }
        groupedProducts[name].push(key);
      }
      setGroups(groupedProducts)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function get_user_data() {
    try {
      let cart = await Auth.currentSession();
      setUserData(cart.getIdToken().payload["name"]);
    } catch (error) {
      console.error("Error getting user data:", error);
    }
  }

  const filterKeys = (obj: any, isChecked: any) => {
    const newObj: any = {};
    for (const key in obj) {
      for (const groupKey of Object.keys(isChecked)) {
        newObj['x'] = obj['x'];
        if ((isChecked as any)[groupKey] && (groups as any)[groupKey].includes(key)) {
          newObj[key] = obj[key];
          break;
        }
      }
    }
    return newObj;
  }

  const setProperChartData = () => {
    let isAnyEnabled = false;
    for (const elem in isChecked) {
      if ((isChecked as any)[elem]) {
        isAnyEnabled = true;
        break;
      }
    }
    if(!isAnyEnabled) {
      setChartData(allChartData)
      setDynamicColumnsData(allChartData);
    } else {
      const newData: any = allChartData.map(item => filterKeys(item, isChecked))
      setChartData(newData);
      setDynamicColumnsData(newData);
    }
  }

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setIsChecked({
      ...isChecked,
      [name]: checked
    });
    (isChecked as any)[name] = checked;
    setProperChartData();
  };

  const handleSingleClick = (event: any) => {
    const newData: any = allChartData.map((item: any) => filterKeysV2(item, event.subgroup))
    if (singleData) {
      setProperChartData();
      setSingleData(false);
    } else {
      setChartData(newData);
      setDynamicColumnsData(newData);
      setSingleData(true);
    }
  }

  return (
    <ContainerLayout data={userData}>
      {
        <div>
        <div style={{ width: "100%" }}>
          <div style={{ float: "left" }}>
            <h1>Purchase Data Analysis</h1>
          </div>
          <br></br>
          <br></br>
          <div style={{ width:"100%", marginLeft:"10px" }}>

          <label>
            <input
              type="radio"
              value="totalData"
              checked={selectedValue === 'totalData'}
              onChange={handleRadioChange}
            />
            &nbsp;&nbsp;
            Product Total Data
          </label>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <label>
            <input
              type="radio"
              value="countData"
              checked={selectedValue === 'countData'}
              onChange={handleRadioChange}
            />
            &nbsp;&nbsp;
            Product Count Data
          </label>

          </div>
          <div style={{ float: "right", display: "block", position: "sticky" }}>
            { columns && columns.length > 0 &&
              <div style={{ marginLeft: "10px", marginTop: "50px", width: "200px", position: "sticky" }}>
              {<table>
                <thead>
                  {
                    <tr>
                      <th>Color</th>
                      <th>Description</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {columns.map((defaultColumn, index) => {
                    lineChartData.find(
                      (series: any) => series.series === defaultColumn
                    );
                    return (
                      <tr key={defaultColumn}>
                        <td>
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              backgroundColor: colors[index],
                              border: "1px solid #ccc",
                            }}
                          ></div>
                        </td>
                        <td>{`${defaultColumn}  `}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              }
            </div>
            }
          </div>
        </div>
        <br />
        <br />
        <br />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
          <div>
            <StackedBarplot data={chartData} columns={columns} colors={colors} width={900} height={400} onclick={handleSingleClick} />
            <br></br>
            {Object.keys(groups).map((option) => (
          <label key={option} style={{ marginLeft: '10px'}}>
            <input
              type="checkbox"
              name={option}
              checked={(isChecked as any)[option]}
              onChange={handleCheckboxChange}
            />
            &nbsp;
            {option}
          </label>
        ))}
        <br />
          </div>
        )}
        <div
          style={{ margin: "40px 20px", float: "right", marginRight: "80px" }}
        ></div>
      </div>
      }
      <div>
    </div>
    </ContainerLayout>
  );
};

export default PurchaseDataContainer;
