// DashBoardContainer.tsx
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Layout, Icon } from "antd";

import SideNavigation from "../SideNavigationContainer/index";

const DashBoardContainer: React.SFC<RouteComponentProps> = (props) => {
  const [data, setData] = useState<string | null>(null);
  const [collapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    get_user_data();
  }, []);

  async function get_user_data() {
    try {
      let cart = await Auth.currentSession();
      setData(cart.getIdToken().payload["name"]);
    } catch (error) {
      console.error("Error getting user data:", error);
    }
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="cover" id="app-header">
      <Layout.Sider
        className="cover"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        {/* Use the SideNavigation component here */}
        <SideNavigation />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: "#ffffff", padding: 10 }}>
          <Icon
            style={{ verticalAlign: "super" }}
            className="trigger"
            onClick={toggleCollapsed}
            type={collapsed ? "menu-unfold" : "menu-fold"}
          />
          <div
            style={{
              verticalAlign: "super",
              float: "right",
              paddingRight: 10,
            }}
          >
            <h1> Hi {data}</h1>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#ffffff",
            minHeight: 280,
          }}
        >
          <div className="text-center">
            <iframe
              title="Quicksight Chart"
              width="1220"
              height="670"
              src="https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/537416613611/dashboards/7b74e0e1-5618-4f23-a109-513c1fbbb466?directory_alias=datakraken"
            ></iframe>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(DashBoardContainer);
