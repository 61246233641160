import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

interface DataPoint {
  x: number;
  y: number;
}

interface Series {
  series: string;
  values: DataPoint[];
}

interface LineChartProps {
  data: Series[];
  width: number;
  height: number;
  colors: Record<string, string>;
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  width,
  height,
  colors,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    const margin = { top: 20, right: 30, bottom: 60, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const parseDate = d3.timeParse("%Y-%m-%d");

    const x = d3
      .scaleTime()
      .domain([
        d3.min(data, (series) =>
          d3.min(series.values, (d) => parseDate(d.x.toString()))
        )!,
        d3.max(data, (series) =>
          d3.max(series.values, (d) => parseDate(d.x.toString()))
        )!,
      ])
      .range([margin.left, innerWidth]);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (series) => d3.max(series.values, (d) => d.y) || 0)!,
      ])
      .range([innerHeight, margin.top]);

    data.forEach((series) => {
      const line = svg
        .append("path")
        .datum(series.values)
        .attr("fill", "none")
        .attr("stroke", colors[series.series])
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line<DataPoint>()
            .x((d) => x(parseDate(d.x.toString())!))
            .y((d) => y(d.y))
        );

      const totalLength = line.node()!.getTotalLength();

      line
        .attr("stroke-dasharray", totalLength + " " + totalLength)
        .attr("stroke-dashoffset", totalLength)
        .transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0);

      svg
        .selectAll("dot")
        .data(series.values)
        .enter()
        .append("circle")
        .attr("r", 5)
        .attr("cx", (d) => x(parseDate(d.x.toString())!))
        .attr("cy", (d) => y(d.y))
        .attr("fill", colors[series.series])
        // Add tooltip
        .on("mouseover", function (event, d) {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip.html(`Date: ${d.x}<br>${series.series}: ${d.y}`)
            .style("left", `${event.pageX}px`)
            .style("top", `${event.pageY - 28}px`);
        })
        .on("mouseout", function () {
          tooltip.transition().duration(500).style("opacity", 0);
        });
    });

    const xAxis = d3.axisBottom(x)
      .tickFormat((date: any) => d3.timeFormat("%B %d, %Y")(date) as unknown as string)
      .ticks(d3.timeDay.every(1));

    svg
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(y));

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    return () => {
      tooltip.remove();
    };
  }, [data, width, height, colors]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
};

export default LineChart;
