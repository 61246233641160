import React, { useState, useEffect } from "react";
import { Layout, Icon, DatePicker } from "antd";
import SideNavigation from "../SideNavigationContainer";
import { Auth } from "aws-amplify";
import LineChart from "../../Components/LineChart";
import { orderBy } from "lodash";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { ThreeDots } from "react-loader-spinner";
import { TextField } from "@mui/material";

import moment, { Moment } from "moment";
const { Content } = Layout;
const { RangePicker } = DatePicker;

const defaultFromDate = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD");
const defaultToDate = moment(new Date()).format("YYYY-MM-DD");

interface YourComponentState {
  disabledDate: (current: Moment) => boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const defaultColumns = ["sms_outbound", "accounts"];

const ContainerLayout: React.FC<{
  data: string | null;
}> = ({ children, data }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="cover" id="app-header">
      <Layout.Sider
        className="cover"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        <SideNavigation />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: "#ffffff", padding: 10 }}>
          <Icon
            style={{ verticalAlign: "super" }}
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={toggleCollapsed}
          />
          <div
            style={{ verticalAlign: "super", float: "right", paddingRight: 10 }}
          >
            <h1> Hi {data}</h1>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#ffffff",
            minHeight: 280,
            flex: "none",
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const SMSDataContainer: React.FC = () => {
  const [userData, setUserData] = useState<string | null>(null);
  const [allChartData, setAllChartData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [selectedDates, setSelectedDates] = useState<any[]>([defaultFromDate, defaultToDate]);

  const handleDateChange = (dates: any) => {
    setSelectedDates(dates);
    setLoading(true);
    fetchData([
      moment(dates[0]).format("YYYY-MM-DD"),
      moment(dates[1]).format("YYYY-MM-DD")
    ])
  };

  // Define custom colors for specific columns
  const customColors = {
    accounts: "red",
    sms_outbound: "blue",
    cost: "yellow",
    tax_amount: "green",
    used_counter_values_integer: "violet",
    smsed_b_numbers: "purple",
    paid_for_smses: "pink",
    international_smses: "black",
    onnet_smses: "orange",
  };

  useEffect(() => {
    fetchData(selectedDates);
    get_user_data();
  }, []);

  const fetchData = async (dates: string[] = []) => {
    try {
      let url = "https://blekg0rmw1.execute-api.eu-west-2.amazonaws.com/dev/sms/data"
      if (dates.length) {
        url += `?startdate=${dates[0]}&&enddate=${dates[1]}`
      }
      const response = await fetch(
        url
      );
      const responseData = await response.json();
      const dynamicColumns = [];
      for (let elem of responseData.data) {
        if (!defaultColumns.includes(elem.series))
          dynamicColumns.push(elem.series);
        for (const value of elem.values) {
          const date = new Date(value.x);
          value.x = date.toISOString().split("T")[0];
          value.y = Number(value.y);
        }
        elem.values = orderBy(elem.values, ["x"], "asc");
      }
      setColumns(dynamicColumns as any);
      setDynamicColumnsOnChart(responseData.data);
      setAllChartData(responseData.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const setDynamicColumnsOnChart = (data: any, values: string[] = []) => {
    const response: any = [];
    for (const elem of data) {
      if (defaultColumns.includes(elem.series)) {
        response.push({
          ...elem,
          color:
            (customColors as Record<string, string>)[elem.series] ||
            getRandomColor(),
        });
      }
    }
    if (values.length) {
      for (const elem of data) {
        if ([...values].includes(elem.series)) {
          response.push({
            ...elem,
            color:
              (customColors as Record<string, string>)[elem.series] ||
              getRandomColor(),
          });
        }
      }
    }
    setLineChartData(response);
  };

  const [selectedColumnNames, setSelectedColumnNames] = React.useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedColumnNames>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedColumnNames(
      typeof value === "string" ? value.split(",") : value
    );
    setDynamicColumnsOnChart(
      allChartData,
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function get_user_data() {
    try {
      let cart = await Auth.currentSession();
      setUserData(cart.getIdToken().payload["name"]);
    } catch (error) {
      console.error("Error getting user data:", error);
    }
  }

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const disabledDate = (current: Moment | null) => {
    if (!current) return false; // Handle null case

    // Calculate the start date of the active range (one month prior to today)
    const activeStartDate = moment().set("year", 2022);
    // Calculate the end date of the active range (today)
    const activeEndDate = moment().endOf("day");

    // Enable dates within the active range
    return current && (current < activeStartDate || current > activeEndDate);;
  };

  return (
    <ContainerLayout data={userData}>
      <div>
        <div style={{ width: "100%" }}>
          <div style={{ float: "left" }}>
            <h1>Multi-Series Line Chart</h1>
          </div>
          <div style={{ float: "right", display: "block", height: "45px" }}>
            <div style={{ width: 300, marginLeft: "8px", textAlign: "left" }}>
              <RangePicker
                style={{ color: 'black', textAlign: 'left' }}
                disabledDate={disabledDate}
                allowClear={false}
                defaultValue={[moment(selectedDates[0]), moment(selectedDates[1])]}
                separator="-"
                onChange={handleDateChange}
              />
            </div>
            <FormControl sx={{ m: 1, width: 300, height: "32px", marginTop: "20px" }}>
              <InputLabel id="demo-multiple-checkbox-label" sx={{ fontSize: '12px', marginTop: '-8px' }}>Columns</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedColumnNames}
                onChange={handleChange}
                input={<OutlinedInput label="Columns" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                sx={{ height: '32px' }}
              >
                {columns.map((name, index) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={selectedColumnNames.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ marginLeft: "10px", marginTop: "50px" }}>
              <table>
                <thead>
                  <tr>
                    <th>Color</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {defaultColumns.map((defaultColumn) => {
                    lineChartData.find(
                      (series: any) => series.series === defaultColumn
                    );
                    return (
                      <tr key={defaultColumn}>
                        <td>
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              backgroundColor:
                                (customColors as Record<string, string>)[
                                  defaultColumn
                                ] || "transparent",
                              border: "1px solid #ccc",
                            }}
                          ></div>
                        </td>
                        <td>{`${defaultColumn}  `}</td>
                      </tr>
                    );
                  })}
                  {selectedColumnNames.map((selectedName) => {
                    lineChartData.find(
                      (series: any) => series.series === selectedName
                    );
                    return (
                      <tr key={selectedName}>
                        <td>
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              backgroundColor:
                                (customColors as Record<string, string>)[
                                  selectedName
                                ] || "transparent",
                              border: "1px solid #ccc",
                            }}
                          ></div>
                        </td>
                        <td>{`${selectedName}  `}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
          <LineChart
            data={lineChartData}
            width={800}
            height={600}
            colors={customColors}
          />
        )}
        <div
          style={{ margin: "40px 20px", float: "right", marginRight: "80px" }}
        ></div>
      </div>
    </ContainerLayout>
  );
};

export default SMSDataContainer;
