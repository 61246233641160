import React, { useState, useEffect } from "react";
import { Table, Layout, Icon } from "antd";
import SideNavigation from "../SideNavigationContainer";
import { Auth } from "aws-amplify";
import LineChart from "../../Components/LineChart";

interface FeedData {
  srno: number;
  feed_name: string;
  latest_file_timestamp: string;
}

const ContainerLayout: React.FC<{
  data: string | null;
}> = ({ children, data }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="cover" id="app-header">
      <Layout.Sider
        className="cover"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        <SideNavigation />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: "#ffffff", padding: 10 }}>
          <Icon
            style={{ verticalAlign: "super" }}
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={toggleCollapsed}
          />
          <div
            style={{ verticalAlign: "super", float: "right", paddingRight: 10 }}
          >
            <h1> Hi {data}</h1>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#ffffff",
            minHeight: 280,
            flex: 'none'
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const FeedContainer: React.FC = () => {
  const [data, setData] = useState<FeedData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<string | null>(null); // Store user data separately

  useEffect(() => {
    fetchData();
    get_user_data();
  }, []);

  async function get_user_data() {
    try {
      let cart = await Auth.currentSession();
      setUserData(cart.getIdToken().payload["name"]); // Store user data separately
    } catch (error) {
      console.error("Error getting user data:", error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://blekg0rmw1.execute-api.eu-west-2.amazonaws.com/dev/list/data"
      );
      const responseData = await response.json();
      setData(
        responseData.data.map((item: any, index: number) => ({
          ...item,
          srno: index + 1,
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Process",
      dataIndex: "process_id",
      key: "process_id",
      sorter: (a: any, b: any) => a.process_id.localeCompare(b.process_id)
    },
    {
      title: "Latest Timestamp",
      dataIndex: "latest_timestamp",
      key: "latest_timestamp",
      render: (text: string) => text ? new Date(text).toLocaleString() : '-',
      sorter: (a: any, b: any) => a.latest_timestamp.localeCompare(b.latest_timestamp)
    },
    {
      title: "Next Timestamp",
      dataIndex: "next_timestamp",
      key: "next_timestamp",
      render: (text: string) => text ? new Date(text).toLocaleString() : '-',
      sorter: (a: any, b: any) => a.next_timestamp.localeCompare(b.next_timestamp)
    },
  ];

  return (
    <ContainerLayout data={userData}>
      {" "}
      {/* Pass userData instead of data */}
      <div style={{ padding: "10px", width: "50%" }}>
        <Layout.Content style={{ padding: "10px" }}>
          <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={{ pageSize: 20 }}
          />
        </Layout.Content>
      </div>
    </ContainerLayout>
  );
};

export default FeedContainer;
