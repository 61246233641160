import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Layout, Icon } from "antd";
import SideNavigation from "../SideNavigationContainer/index";
import { RouteComponentProps, withRouter } from "react-router-dom";

const ContainerLayout: React.FC<{
  data: string | null;
}> = ({ children, data }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="cover" id="app-header">
      <Layout.Sider
        className="cover"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        <SideNavigation />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: "#ffffff", padding: 10 }}>
          <Icon
            style={{ verticalAlign: "super" }}
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={toggleCollapsed}
          />
          <div
            style={{ verticalAlign: "super", float: "right", paddingRight: 10 }}
          >
            <h1> Hi {data}</h1>
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#ffffff",
            minHeight: 280,
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const ProductContainer: React.FC<RouteComponentProps> = (props) => {
  const [data, setData] = useState<string | null>(null);

  useEffect(() => {
    get_user_data();
  }, []);

  async function get_user_data() {
    try {
      let cart = await Auth.currentSession();
      setData(cart.getIdToken().payload["name"]);
    } catch (error) {
      console.error("Error getting user data:", error);
    }
  }

  return (
    <ContainerLayout data={data}>
      Product QuickSight Chart:
      <div className="text-center">
        <br />
        <iframe
          title="Quicksight Chart"
          width="1220"
          height="670"
          src="https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/537416613611/dashboards/c9db672a-443f-4142-8611-7f289f0e0954?directory_alias=datakraken"
        ></iframe>
      </div>
    </ContainerLayout>
  );
};

export default withRouter(ProductContainer);
