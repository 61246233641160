import styled from "styled-components";
import { Form } from "antd";

const FormWrapper = styled(Form)`
  max-width: 350px;
  margin-right: 60px;
  margin: 7% auto !important;
  margin-bottom: 40px;
  background: #ffffff;
`;

export default FormWrapper;
