import * as React from "react";
import { Redirect, Link, RouteComponentProps } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { Form, Icon, Spin, Input, Button, notification, Col, Row } from "antd";
import UserPoolData from "../../Assets/config";
import QRCode from "qrcode.react";
import FormWrapper from "../../Components/FormWrapper";
import image from "../../Assets/IRIS-Logo.png";
import { Grid } from "@mui/material";

Amplify.configure({
  Auth: {
    userPoolWebClientId: UserPoolData.clientId,
    userPoolId: UserPoolData.userPoolId,
    region: "eu-west-2",
  },
});

type Props = RouteComponentProps & {
  form: any;
};

type State = {
  loading: boolean;
  QRCode: string;
  showQRCode: boolean;
  cognitoUser: any;
  redirect: boolean;
  displayQRCodeAgain: boolean;
  showNewPasswordForm: boolean;
  email: string;
};

const defaultStateValue = {
  loading: false,
  QRCode: "",
  showQRCode: false,
  cognitoUser: {},
  redirect: false,
  displayQRCodeAgain: false,
  showNewPasswordForm: false,
  email: "",
};

class LoginContainer extends React.Component<Props, State> {
  state = defaultStateValue;

  handleSubmitMFA = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.form.validateFields(
      async (
        err: Error,
        values: {
          token: string;
          newPassword: string;
          password: string;
          username: string;
        }
      ) => {
        if (!err) {
          let { token } = values;
          this.setState({ loading: true });

          let user = null;
          if (this.state.displayQRCodeAgain) {
            user = await Auth.verifyTotpToken(this.state.cognitoUser, token);
          } else if (this.state.showNewPasswordForm) {
            await Auth.completeNewPassword(
              this.state.cognitoUser,
              values.newPassword,
              {
                name: this.state.email,
              }
            );
            notification.info({
              message: "New Password Set Successfully",
              description: "Login with new password to explore more",
              placement: "topRight",
              duration: 1.5,
              onClose: () => {
                this.setState({ ...defaultStateValue });
              },
            });
          } else {
            user = await Auth.confirmSignIn(
              this.state.cognitoUser,
              token,
              "SOFTWARE_TOKEN_MFA"
            ).catch((e) => {
              notification.error({
                message: "Something went wrong!",
                description: e.message,
                placement: "topRight",
                duration: 1.5,
                onClose: () => {
                  this.setState({ redirect: false, loading: false });
                },
              });
            });
          }

          if (user) {
            notification.success({
              message: "Succesfully logged in user!",
              description: "Logged in successfully, Redirecting you in a few!",
              placement: "topRight",
              duration: 1.5,
              onClose: () => {
                this.setState({ redirect: true });
              },
            });
          }
        }
      }
    );
  };

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    this.props.form.validateFields(
      async (err: Error, values: { username: string; password: string }) => {
        if (!err) {
          let { username, password } = values;

          this.setState({ loading: true });
          const user = await Auth.signIn(username, password).catch((e) => {
            notification.error({
              message: "Something went wrong!",
              description: e.message,
              placement: "topRight",
              duration: 1.5,
            });
          });
          if (!user) {
            this.setState({ ...defaultStateValue });
            return;
          }
          this.setState({ cognitoUser: user });
          this.state.email = username;

          if (user.challengeName === "MFA_SETUP") {
            const res = await Auth.setupTOTP(user);
            const authCode =
              "otpauth://totp/AWSCognito:" +
              username +
              "?secret=" +
              res +
              "&issuer=Cognito";
            this.setState({
              QRCode: authCode,
              showQRCode: true,
              loading: false,
              displayQRCodeAgain: true,
            });
          } else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
            this.setState({
              showQRCode: true,
              loading: false,
              displayQRCodeAgain: false,
              showNewPasswordForm: false,
            });
          } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.setState({
              showQRCode: true,
              loading: false,
              displayQRCodeAgain: false,
              showNewPasswordForm: true,
            });
          } else {
            notification.error({
              message: "Something went wrong!",
              description: user.message,
              placement: "topRight",
              duration: 1.5,
              onClose: () => {
                this.setState({ redirect: true });
              },
            });
          }
        } else {
          notification.error({
            message: "Something went wrong!",
            description: err.message,
            placement: "topRight",
            duration: 1.5,
            onClose: () => {
              this.setState({ redirect: false });
            },
          });
        }
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, redirect } = this.state;

    return (
      <React.Fragment>
        {!this.state.showQRCode && (
          <div>
            <img
              src={image}
              style={{ height: "150px", width: "350px" }}
              alt="Logo"
            />
            <Grid container>
              <Grid
                item
                md={6}
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1
                    style={{
                      fontSize: "48px",
                    }}
                  >
                    Welcome
                  </h1>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        content: '""',
                        borderLeft: "2px solid red", // Change the color and width as needed
                        height: "45px", // Adjust the height based on your design
                        marginRight: "20px", // Adjust the margin as needed
                      }}
                    ></div>
                    <p
                      style={{
                        fontSize: "16px",
                        width: "300px",
                      }}
                    >
                      Log in and view your data with the Iris platform
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start", // Align content to the start
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <FormWrapper
                  onSubmit={this.handleSubmit}
                  style={{ width: "-webkit-fill-available" }}
                >
                  <Form.Item>
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ],
                    })(
                      <Input
                        style={{ borderRadius: "10px", height: "45px" }}
                        // prefix={<Icon type="user" style={{ color: "#000000" }} />}
                        placeholder="Email"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ],
                    })(
                      <Input
                        style={{ borderRadius: "10px", height: "45px" }}
                        // prefix={<Icon type="lock" style={{ color: "#000000" }} />}
                        type="password"
                        placeholder="Password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item className="text-center">
                    <Row type="flex" gutter={16}>
                      <Col lg={24}>
                        <Button
                          style={{ width: "100%" }}
                          type="primary"
                          disabled={loading}
                          htmlType="submit"
                          className="login-form-button"
                        >
                          {loading ? (
                            <Spin
                              indicator={
                                <Icon
                                  type="loading"
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Log in"
                          )}
                        </Button>
                      </Col>
                      <Col
                        lg={24}
                        xs={18}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          textAlign: "right",
                        }}
                      >
                        {/* <div>
                  Or <Link to="/signup">Register now!</Link>
                </div> */}
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </Col>
                    </Row>
                  </Form.Item>
                </FormWrapper>
              </Grid>
            </Grid>
          </div>
        )}
        {this.state.showQRCode && !this.state.showNewPasswordForm && (
          <FormWrapper
            onSubmit={(event) => this.handleSubmitMFA(event)}
            className="login-form"
          >
            {/* <img src={this.state.QRCode} /> */}
            {this.state.displayQRCodeAgain && (
              <div>
                <span>Scan below QR code using Authenticator App</span>
              </div>
            )}
            {this.state.displayQRCodeAgain && (
              <div>
                <QRCode
                  style={{ marginTop: "10px" }}
                  value={this.state.QRCode}
                />
              </div>
            )}

            <Form.Item style={{ marginTop: "10px" }}>
              {/* {this.state.showNewPasswordForm && (
          <Input
            prefix={<Icon type="lock" style={{ color: "#000000" }} />}
            placeholder="Set New Password"
            type="password"
          />
        )} */}
              {getFieldDecorator("token", {
                rules: [
                  {
                    required: true,
                    message: "Please input code!",
                  },
                ],
              })(
                <div>
                  <span>
                    Enter Auth Code for <b>{this.state.email}</b>
                  </span>
                  <br></br>
                  <Input
                    prefix={<Icon type="user" style={{ color: "#000000" }} />}
                    placeholder="Auth Code"
                  />
                </div>
              )}
            </Form.Item>
            <Form.Item className="text-center">
              <Row type="flex" gutter={16}>
                <Col lg={24}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <Icon type="loading" style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : (
                      "Validate and Log in"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </FormWrapper>
        )}
        {this.state.showNewPasswordForm && (
          <FormWrapper
            onSubmit={(event) => this.handleSubmitMFA(event)}
            className="login-form"
          >
            <Form.Item>
              {getFieldDecorator("newPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please input new password!",
                  },
                ],
              })(
                <div>
                  <span> Enter New Password </span>
                  <br></br>
                  <Input
                    prefix={<Icon type="lock" style={{ color: "#000000" }} />}
                    placeholder="Set New Password"
                    type="password"
                  />
                </div>
              )}
            </Form.Item>
            <Form.Item className="text-center">
              <Row type="flex" gutter={16}>
                <Col lg={24}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <Icon type="loading" style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : (
                      "Set New Password"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </FormWrapper>
        )}

        {redirect && <Redirect to={{ pathname: "/dashboard" }} />}
      </React.Fragment>
    );
  }
}

export default Form.create()(LoginContainer);
