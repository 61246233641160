// SideNavigation.tsx
import React, { useEffect, useState } from "react";
import { Menu, Icon } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { notification } from "antd";

const SideNavigation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  const navigate = (route: string) => {
    history.push(route);
    setSelectedKeys([route]); // Update selectedKeys when navigating
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({});
      history.push("/login");
    } catch (err: any) {
      notification.error({ message: err?.message });
    }
  };

  return (
    <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
      <Menu.Item key="/dashboard" onClick={() => navigate("/dashboard")}>
        <Icon type="home" />
        <span>Home</span>
      </Menu.Item>
      <Menu.Item key="/product" onClick={() => navigate("/product")}>
        <Icon type="plus" />
        <span>Product</span>
      </Menu.Item>
      <Menu.Item key="/daily-sms" onClick={() => navigate("/daily-sms")}>
        <Icon type="line-chart" />
        <span>Daily SMS</span>
      </Menu.Item>
      <Menu.Item key="/daily-data" onClick={() => navigate("/daily-data")}>
        <Icon type="line-chart" />
        <span>Daily Data</span>
      </Menu.Item>
      <Menu.Item key="/purchase-data" onClick={() => navigate("/purchase-data")}>
        <Icon type="bar-chart" />
        <span>Purchase Data</span>
      </Menu.Item>
      <Menu.Item key="/calendar" onClick={() => navigate("/calendar")}>
        <Icon type="calendar" />
        <span>Calendar</span>
      </Menu.Item>
      <Menu.Item key="/feed" onClick={() => navigate("/feed")}>
        <Icon type="message" />
        <span>Feed</span>
      </Menu.Item>
      <Menu.Item key="/logout" onClick={handleLogout}>
        <Icon type="logout" />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );
};

export default SideNavigation;
